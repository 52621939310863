var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标题:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "描述:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.desc,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "desc", $$v)
                  },
                  expression: "formData.desc"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "唯一code:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.uniqueId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "uniqueId", _vm._n($$v))
                  },
                  expression: "formData.uniqueId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "分组:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.group,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "group", _vm._n($$v))
                  },
                  expression: "formData.group"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.back } },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }